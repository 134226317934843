import React, { Fragment } from "react";
import { Layout, SEO } from "src/components";
import {
  HeroTemplate,
  PressBannerTemplate,
  QuickLinksTemplate,
  CateringInformation,
  CateringFaqTemplate,
  GalleryTemplate,
  CateringFormTemplate
} from "src/services/prismic/templates";
import { graphql } from "gatsby";
import { PrismicPage } from "src/graphqlTypes";

type IndexProps = {
  data: {
    prismicPage: PrismicPage;
  };
};

const Catering = ({ data: { prismicPage: page } }: IndexProps) => {
  return (
    <Fragment>
      <SEO
        title={page.data?.title.text}
        description={page.data?.description.text}
        path="/catering"
      />
      <Layout>
        {page?.data?.body?.map((slice, idx) => {
          switch (slice.__typename) {
            case "PrismicPageBodyHero":
              return <HeroTemplate data={slice} key={idx} />;
            case "PrismicPageBodyPressLogos":
              return <PressBannerTemplate data={slice} key={idx} />;
            case "PrismicPageBodyQuicklinksSection":
              return <QuickLinksTemplate data={slice} key={idx} />;
            case "PrismicPageBodyCateringInformation":
              return <CateringInformation data={slice} key={idx} />;
            case "PrismicPageBodyFaqSection":
              return <CateringFaqTemplate data={slice} key={idx} />;
            case "PrismicPageBodyGallery":
              return <GalleryTemplate data={slice} key={idx} />;
            case "PrismicPageBodyCateringForm":
              return <CateringFormTemplate data={slice} key={idx} />;
            default:
              return null;
          }
        })}
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query {
    prismicPage(uid: { eq: "catering" }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PrismicHero
          ...PrismicPressLogos
          ...QuickLinks
          ...CateringInformation
          ...PrismicFaqSection
          ...Gallery
          ...CateringForm
        }
      }
    }
  }
`;

export default Catering;
